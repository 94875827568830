import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/bem",
  "date": "2015-12-29",
  "title": "BEM",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "BEM stands for Block, Element, Modifier. BEM is a stricter standard for naming CSS classes for larger projects, where standards need to be enforced. BEM helps scale your project, and add meaning to your CSS. Therefore it is valuable to understand this convention."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Block`}</h2>
    <p>{`A logically and functionally independent page component, the equivalent of a component in Web Components. A block encapsulates behaviour (JavaScript), templates, styles (CSS), and other implementation technologies described below.`}</p>
    <p>{`Blocks can be implemented in one or more technologies, for example:`}</p>
    <ul>
      <li parentName="ul">{`Behavior — JavaScript, CoffeeScript, TypeScript`}</li>
      <li parentName="ul">{`Appearance - CSS, Stylus, Sass, Less`}</li>
      <li parentName="ul">{`Templates — BEMHTML, BH, Jade, Handlebars, XSL`}</li>
      <li parentName="ul">{`Documentation - Markdown, Wiki, XML`}</li>
    </ul>
    <Message type="info" title="" content="Blocks being independent allows for their re-use, as well as facilitating the project development and support process." mdxType="Message" />
    <h3>{`Block Features`}</h3>
    <p>{`Blocks consists of the following features:`}</p>
    <ul>
      <li parentName="ul">{`Nested - Blocks can be nested inside any other blocks`}</li>
      <li parentName="ul">{`Arbitrary placement - Blocks can be moved around on a page, moved between pages or projects`}</li>
      <li parentName="ul">{`Re-use - An interface can contain multiple instances of the same block`}</li>
    </ul>
    <h3>{`Nested structure`}</h3>
    <p>{`Block can be nested inside any other blocks.`}</p>
    <h2>{`Element`}</h2>
    <p>{`A constituent part of a block that can't be used outside of it. For example, a menu item is not used outside of the context of a menu block, therefore it is an element.`}</p>
    <Message type="warn" title="" content="Using elements within elements is not recommended by the BEM methodology." mdxType="Message" />
    <h2>{`Modifier`}</h2>
    <p>{`A BEM entity that defines the appearance and behaviour of a block or an element.`}</p>
    <p>{`The use of modifiers is optional.`}</p>
    <p>{`Modifiers are similar in essence to HTML attributes. The same block looks different due to the use of a modifier.`}</p>
    <p>{`For instance, the appearance of the menu block (menu) may change depending on a modifier that is used on it.`}</p>
    <h2>{`Naming Convension`}</h2>
    <p>{`The naming convention follows this pattern:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.block {}
.block__element {}
.block--modifier {}
`}</code></pre>
    <p>{`As you can notice, the element (__) differs from modifier (--), where element uses underscores and modifier use hyphens.`}</p>
    <p>{`These self-describing styles help developers and designers to identify and understand the structure they are building.`}</p>
    <p>{`Let's look at an example to understand this concept further.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.vehicle {}
.vehicle__door {}
.vehicle--car {}
.vehicle--car__door {}
.vehicle__door--left {}
`}</code></pre>
    <p>{`The top-level block is `}<inlineCode parentName="p">{`vehicle`}</inlineCode>{` which has elements for example `}<inlineCode parentName="p">{`door`}</inlineCode>{`. A `}<inlineCode parentName="p">{`vehicle`}</inlineCode>{` also has variation, like `}<inlineCode parentName="p">{`car`}</inlineCode>{`. And that variation in turn has elements such as `}<inlineCode parentName="p">{`door`}</inlineCode>{`.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`BEM may seem ugly, and weird. But it's a powerful naming convention for the front-end developer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      